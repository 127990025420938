<template>
  <div id="app" class="flex flex-col">
    <Navbar/>
    <router-view/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';

export default {
    components: {
        Navbar,
    }
};
</script>
